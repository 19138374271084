<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-expand-transition>
        <v-col cols="12" v-if="copy">
          <v-select
            :items="lineups"
            v-model="selected"
            label="Select a lineup to copy"
            item-text="name"
            return-object
            :loading="loading"
            color="color3"
            item-color="color3"
            placeholder="Select A Lineup"
            outlined
            :disabled="disabled"
          ></v-select>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" v-if="!copy">
          <v-row dense>
            <v-col cols="6" class="text-left">
              <ncaa-print
                v-if="division.isNcaa && valid"
                :homeLineup="iLineup"
                :homeTeam="team"
                :location="division.location"
                :awayTeam="opponent"
                :date="date"
              ></ncaa-print>
            </v-col>
            <v-col cols="6" class="text-end">
              <v-btn
                :disabled="disabled"
                color="color3"
                text x-small
                v-if="lineups.length > 0"
                @click.stop="toggleCopy"
              >copy a lineup</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col v-if="iLineup" cols="12" class="text-center">
          <lineup-details
            :startInEdit="!disabled"
            :lineup="iLineup"
            :division="division"
            :team="team"
            @cancel-click="onCancelClick"
            :tokenStatus="tokenStatus"
            :hideName="true"
            ref="details"
            :disabled="disabled"
          ></lineup-details>
        </v-col>
      </v-expand-transition>
    </v-row>
  </v-container>
</template>

<script>
import LineupDetails from './LineupDetails'
import Lineup from '@/classes/Lineup'
import { mapGetters } from 'vuex'
const NcaaPrint = () => import('./NcaaPrint.vue')

export default {
  props: ['team', 'tournament', 'division', 'tokenStatus', 'currentLineup', 'active', 'name', 'disabled', 'match', 'round'],
  data () {
    return {
      lineups: [],
      loading: false,
      selected: null,
      copy: false,
      iLineup: null
    }
  },
  computed: {
    ...mapGetters(['getTeamToken', 'getTeam']),
    date () {
      return (this.match && this.match.startTime) || this.round.date
    },
    opponent () {
      const m = this.match
      const h = m.homeTeam
      const a = m.awayTeam
      if (this.team.id === h.teamId) return this.getTeam(a.teamId)

      return this.getTeam(h.teamId)
    },
    selectedLineup () {
      return this.lineups && this.selected && this.lineups.find(f => f.id === this.selected)
    },
    dto () {
      return this.iLineup.dto
    },
    saveable () {
      return this.valid && this.dirty
    },
    valid () {
      return this.iLineup && this.iLineup.valid
    },
    dirty () {
      return this.iLineup && (this.iLineup.id === 0 || JSON.stringify(this.iLineup.positions) !== JSON.stringify(this.incoming.positions))
    },
    incoming () {
      return this.currentLineup && JSON.parse(JSON.stringify(this.currentLineup))
    },
    teamToken () {
      return this.getTeamToken(this.team.id, this.$route)
    }
  },
  methods: {
    toggleCopy () {
      !this.copy && this.loadLineups()
      this.copy = !this.copy
    },
    copyLineup (val) {
      const newP = JSON.parse(JSON.stringify(val.positions))
      this.iLineup.update({
        positions: newP
      })

      this.selected = null
      this.copy = false
      this.$refs.details && this.$refs.details.edit()
    },
    makeILineup () {
      const incoming = this.currentLineup && JSON.parse(JSON.stringify(this.currentLineup))
      this.iLineup = new Lineup({
        id: incoming ? incoming.id : 0,
        teamId: this.team.id,
        name: this.name || `Line Up ${this.lineups.length + 1}`,
        type: this.division.props.find(f => f.startsWith('lineups-dual-')),
        positions: incoming ? incoming.positions : false
      })
    },
    onCancelClick () {
      this.lineups = this.lineups.filter(f => f.id !== 0)
    },
    loadLineups () {
      this.loading = true
      this.$VBL.team.lineups.get(this.team.id)
        .then(r => {
          this.lineups = r.data.map(m => new Lineup(m))
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    },
    reset () {
      this.copy = false
      this.selected = null
      this.makeILineup()
    }
  },
  mounted () {
    this.reset()
    this.loadLineups()
  },
  watch: {
    active: 'reset',
    loading: function (val) {
      this.$emit('loading-change', val)
    },
    selected: function (val) {
      val && this.copyLineup(val)
    },
    saveable: function (val) {
      this.$emit('valid-change', val)
    }
  },
  components: {
    LineupDetails,
    NcaaPrint
  }
}
</script>
