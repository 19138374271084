<template>
  <v-card class="fill-height flexcard" :flat="hideName">
    <v-card-text class="grow" :class="{ 'pa-0': hideName }">
      <v-row dense>
        <v-col cols="12" v-if="!hideName">
          <v-text-field
            label="Name"
            v-model="model.name"
            :disabled="!editing"
            color="color3"
            outlined
          ></v-text-field>
        </v-col>
        <v-col v-if="players.length < playersNeeded">
          <v-alert type="error" prominent text>
            You do not have enough players on your roster
          </v-alert>
          <v-btn color="color3 color3Text--text" :to="{ name: 'division-team', params: { tournamentId: tournament.id, teamId: team.id }, query: { tab: 'partner' } }">
            Please update your roster
          </v-btn>
        </v-col>
        <template v-else>
          <v-col cols="12" class="pa-0">
            <v-card color="red" class="pa-1">
              <div class="red lighten-4 red--text">Players</div>
              <v-card-text class="white pa-1">
                <v-row dense>
                  <v-col cols="12"
                    v-for="(position, i) in model.starters" :key="position.n"
                  >
                    <v-select
                      class="mt-0" dense
                      :ref="`select${position.n}`"
                      :items="getAvailable(position)"
                      :item-text="item => getPlayerName(item.id)"
                      item-value="id"
                      v-model="position.players"
                      :label="`Pair No. ${position.n}`"
                      multiple attach @change="() => onChange(position)"
                      chips deletable-chips small-chips
                      :disabled="!editing || disabled"
                      hide-details
                      color="color3"
                      item-color="color3"
                      :autofocus="position.n === 1 && position.players.length < 2"
                      append-icon=""
                      outlined
                    ></v-select>
                    <v-btn
                      v-if="editing && showSwapObj[i]"
                      color="color3"
                      small
                      text
                      @click.stop="swap(position.n)"
                    >
                      <v-icon small>fas fa-long-arrow-up</v-icon>
                      <v-icon small>fas fa-long-arrow-down</v-icon>
                    </v-btn>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-card color="green" class="pa-1 mt-3" v-if="model.alternates.length > 0">
              <div class="green lighten-4 green--text">Alternates</div>
              <v-card-text class="white pa-1">
                <v-row dense>
                  <v-col cols="12"
                    v-for="(position, i) in model.alternates" :key="`alt-${i}`"
                  >
                    <v-chip :disabled="disabled" small :close="editing && !disabled" @click:close="removePosition(position)">{{ getPlayerName(position.players[0]) }}</v-chip>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-card color="blue" class="pa-1 mt-3" v-if="model.removedPlayers.length > 0">
              <div class="blue lighten-4 blue--text">Removed Players</div>
              <v-card-text class="white pa-1">
                <v-row dense>
                  <v-col cols="12"
                    v-for="(position, i) in model.removedPlayers"  :key="`removed-${i}`"
                  >
                    <v-chip :disabled="disabled" small :close="editing && !disabled" @click:close="removePosition(position)">{{ getPlayerName(position.players[0]) }}</v-chip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-if="team"
              label="Apply to all future matches"
              v-model="model.applyAll"
              color="success"
              :disabled="!editing || disabled"
            ></v-checkbox>
          </v-col>
        </template>
      </v-row>
      <v-expand-transition>
        <v-row v-if="availablePlayers.length > 0">
          <v-col cols="12">
            <v-card :flat="!editing" :outlined="!editing">
              <v-toolbar
                dense
                :color="editing ? 'color2 color2Text--text' : 'grey lighten-2 grey--text'"
                :flat="!editing"
              >
                <v-toolbar-title>
                  Other Players
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in availablePlayers"
                        :key="item.id"
                      >
                        <td class="text-left">{{ item.name }} {{ item.jersey ? `#${item.jersey}` : '' }}</td>
                        <td class="text-left">
                          <v-fab-transition>
                            <v-btn
                              v-if="model.valid && editing"
                              color="green"
                              text x-small
                              @click.stop="nonStarter(item, 'alt')"
                            >Alternate</v-btn>
                          </v-fab-transition>
                        </td>
                        <td class="text-left">
                          <v-fab-transition>
                            <v-btn
                              v-if="model.valid && editing"
                              color="blue"
                              text x-small
                              @click.stop="nonStarter(item, 'removed')"
                            >Removed</v-btn>
                          </v-fab-transition>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-expand-transition>
    </v-card-text>
    <v-card-actions class="justify-end shrink" v-if="showButtons">
      <v-fab-transition>
        <v-btn
          v-if="editing || (user && user.vbl)"
          color="error"
          x-small text icon
          @click="confirmDelete = true"
          :disabled="loading"
        >
          <v-icon>fas fa-trash</v-icon>
        </v-btn>
      </v-fab-transition>
      <template v-if="false">
        <v-spacer></v-spacer>
        <v-fab-transition>
          <v-btn
            color="error white--text"
            fab small
            @click="cancel"
            :disabled="loading"
            v-if="editing"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            :key="currentBtn.key"
            :color="currentBtn.color"
            :disabled="currentBtn.disabled"
            @click.stop="currentBtn.click"
            :loading="loading"
            fab small
          >
            <v-icon>{{currentBtn.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
    </v-card-actions>
    <v-dialog
      v-model="confirmDelete"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text class="text-center pa-3">
          <div class="title">Are you sure?</div>
          <div class="mb-3">Deleteing a lineup can not be undone</div>
          <v-btn color="success white--text" class="mr-3" @click="doDelete" :loading="loading">Yes</v-btn>
          <v-btn color="error white--text" @click="confirmDelete = false" :disabled="loading">No</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Lineup from '@/classes/Lineup'
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'

export default {
  props: ['lineup', 'team', 'tokenStatus', 'disabled', 'hideName', 'showButtons', 'startInEdit'],
  data () {
    return {
      editable: null,
      editing: false,
      loading: false,
      confirmDelete: false,
      applyAll: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'user']),
    model () {
      return this.lineup
      // return this.editing ? this.editable : this.lineup
    },
    players () {
      return this.team.players
    },
    playersNeeded () {
      return this.lineup.playersNeeded
    },
    takenPlayers () {
      return this.model.players
    },
    availablePlayers () {
      return this.players.filter(f => !this.takenPlayers.includes(f.id))
    },
    valid () {
      return this.dirty // && this.editable.valid
    },
    currentBtn () {
      return this.editing ? {
        icon: 'fas fa-save',
        color: 'success white-text',
        disabled: !this.valid,
        key: 'save',
        click: this.save
      } : {
        icon: 'fas fa-pencil',
        color: 'color3Text color3--text',
        key: 'edit',
        click: this.edit
      }
    },
    dto () {
      return this.model && this.model.dto
    },
    oDto () {
      return this.lineup && this.lineup.dto
    },
    dirty () {
      return this.dto && (this.dto.id === 0 || JSON.stringify(this.dto) !== JSON.stringify(this.lineup.dto))
    },
    showSwapObj () {
      return this.model.starters.map(me => {
        if (me.n === this.model.starters.length) return false
        const before = this.model.starters.find(f => f.n === me.n - 1)
        const after = this.model.starters.find(f => f.n === me.n + 1)
        return me.players.length > 0 || (before && before.players.length > 0) || (after && after.players.length > 0)
      })
    }
  },
  methods: {
    getPlayerName (id) {
      const x = this.players && this.players.find(f => f.id === id)
      if (!x) return 'Missing Player'
      const j = x.jersey ? ` #${x.jersey}` : ''
      return `${x.fullname}${j}`
    },
    swap (n) {
      const top = this.model.positions.find(f => f.n === n)
      const bottom = this.model.positions.find(f => f.n === n + 1)
      if (top && bottom) {
        const t = [...top.players]
        const b = [...bottom.players]
        top.players = [...b]
        bottom.players = [...t]
      }
    },
    edit () {
      this.editable = new Lineup(JSON.parse(JSON.stringify(this.lineup.dto)))
      this.editing = true
    },
    cancel () {
      this.$emit('cancel-click')
      this.editing = false
      this.editable = null
    },
    getAvailable (p) {
      return [...this.availablePlayers, ...this.players.filter(f => p.players.includes(f.id))].sort(firstBy('name'))
    },
    onChange (p) {
      const validPlayerIds = this.players.map(m => m.id)
      p.players = p.players.filter(f => validPlayerIds.includes(f))
      if (p.players.length === 2) {
        const s = `select${p.n}`
        const ref = this.$refs[s]
        ref && ref[0] && ref[0].blur()
        const s2 = `select${p.n + 1}`
        const ref2 = this.$refs[s2]
        ref2 && ref2[0] && ref2[0].focus()
      }
    },
    save () {
      // NOT USED
      if (this.valid) {
        this.loading = true
        this.$VBL.team.lineups.post({ lineup: this.dto }, this.$route.query.token)
          .then(r => {
            this.lineup.update(r.data)
            this.cancel()
          })
          .catch(e => console.log(e))
          .finally(() => { this.loading = false })
      }
    },
    doDelete () {
      this.loading = true
      this.$VBL.team.lineups.delete({ lineup: this.lineup }, this.$route.query.token)
        .then(r => {
          this.lineup.id = 0
          this.cancel()
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    nonStarter (player, type) {
      this.model.positions.push({
        type: type,
        players: [player.id]
      })
    },
    removePosition (position) {
      this.model.removePosition(position)
    }
  },
  watch: {
    valid: function (v) {
      this.$emit('valid-change', v)
    }
  },
  mounted () {
    this.lineup && (this.lineup.id === 0 || this.startInEdit) && this.edit()
  }
}
</script>
